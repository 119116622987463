<template>
  <div>
    <section class="main-content">
      <tabs :hasChanges="hasChanges" v-if="campaign.id" @toggle-change="updateChanges">
        <tab
          v-for="(tab, index) in tabs"
          :key="index"
          :icon="tab.icon"
          :name="tab.title"
          :selected="tab.selected"
        >
          <component
            :is="tab.component"
            :campaign="campaign"
            class="campaign-container"
            @changed="updateChanges"
          ></component>
        </tab>
      </tabs>
    </section>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CampaignById from '@/graphql/queries/campaigns/byId.gql';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';
import updateDataBank from '@/graphql/mutations/dataBank/updateDataBank.gql';
import deleteDataBank from '@/graphql/mutations/dataBank/deleteDataBank.gql';
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_DONE,
  UPDATE_CAMPAIGN_ERROR,
  UPDATE_DATA_BANK,
} from '@/eventTypes';
import errorHandler from '@/utils/error.handler';

export default {
  components: {
    UsersTab: () => import('@/components/settings/Users/UsersTab.vue'),
    CategoriesTab: () => import('@/components/settings/CategoriesTab.vue'),
    DataBankMenu: () => import('@/components/settings/dataBank/DataBankMenu.vue'),
    LabelsTab: () => import('@/components/settings/LabelsTab.vue'),
    MessagesTab: () => import('@/components/settings/MessagesTab.vue'),
    StoreOptionsTab: () => import('@/components/settings/StoreOptionsTab.vue'),
    OptionsTab: () => import('@/components/settings/OptionsTab.vue'),
    ScheduleTab: () => import('@/components/settings/ScheduleTab.vue'),
    Tabs: () => import('@/components/TabsContainer.vue'),
    Tab: () => import('@/components/TabsSingle.vue'),
  },
  data() {
    return {
      hasChanges: false,
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'setting/getCurrentCampaign',
    }),
    campaignId() {
      return this.$route.params.campaign_id;
    },
    tabs() {
      return [
        {
          icon: 'account-multiple-icon',
          title: this.$t('configuration.tabs.assign-users'),
          selected: true,
          component: 'users-tab',
        },
        {
          icon: 'palette-swatch-icon',
          title: this.$t('configuration.tabs.categories'),
          selected: false,
          component: 'categories-tab',
        },
        {
          icon: 'label-icon',
          title: this.$t('configuration.tabs.labels'),
          selected: false,
          component: 'labels-tab',
        },
        {
          icon: 'message-icon',
          title: this.$t('configuration.tabs.automatic-messages'),
          selected: false,
          component: 'messages-tab',
        },
        {
          icon: 'clock-outline-icon',
          title: this.$t('configuration.tabs.schedule'),
          selected: false,
          component: 'schedule-tab',
        },
        {
          icon: 'wrench-icon',
          title: this.$t('configuration.tabs.options'),
          selected: false,
          component: 'options-tab',
        },
        {
          icon: 'store-icon',
          title: this.$t('configuration.tabs.stores'),
          selected: false,
          component: 'store-options-tab',
        },
        {
          icon: 'database-icon',
          title: this.$t('app.data-bank'),
          selected: false,
          component: 'data-bank-menu',
        },
      ];
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN, (data) => {
      this.updateCampaign(data);
    });

    EventBus.$on(UPDATE_DATA_BANK, (payload) => {
      if (payload.remove) {
        this.deleteDataBank(payload.data, payload.type);
      } else {
        this.updateBank(payload.data);
      }
    });
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN);
    EventBus.$off(UPDATE_DATA_BANK);
  },
  apollo: {
    campaign() {
      return {
        query: gql`${CampaignById}`,
        variables() {
          return { id: this.campaignId };
        },
        update(data) {
          this.setCampaign(data.campaign);
          this.updateCampaignStore(data.campaign);
          return data.campaign;
        },
        skip() {
          return !this.campaignId;
        },
      };
    },
  },
  mounted() {
    this.clearDatabank();
  },
  methods: {
    ...mapActions({
      updateCampaignStore: 'setting/updateCampaign',
      editDataBank: 'databank/updateDataBank',
      deleteData: 'databank/deleteData',
      updateStore: 'shop/update',
      clearDatabank: 'databank/clearDatabank',
    }),
    ...mapMutations({
      setCampaign: 'setting/setCurrentCampaign',
    }),
    async updateCampaign({ data, tab }) {
      try {
        const result = await this.$apollo.mutate({
          mutation: editCampaignMutation,
          variables: data,
        });
        if ('schedules' in data || 'messages' in data) {
          const { id, schedules, messages } = result.data.editCampaign;
          await this.updateStore({ id, schedules, messages });
        }
        this.updateCampaignStore(result.data.editCampaign);
        EventBus.$emit(UPDATE_CAMPAIGN_DONE, tab);
      } catch (err) {
        EventBus.$emit(UPDATE_CAMPAIGN_ERROR, err);
      }
    },
    async updateBank(data) {
      try {
        const result = await this.$apollo.mutate({
          mutation: updateDataBank,
          variables: data,
        });
        this.editDataBank(result.data.updateDataBank);
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      }
    },
    async deleteDataBank(data, type) {
      try {
        const res = await this.$apollo.mutate({
          mutation: deleteDataBank,
          variables: data,
        });
        this.deleteData({ res: res.data.deleteDataBank, type });
      } catch (err) {
        errorHandler.logErrors(err);
        this.$toasted.global.error(this.$t('common.error-saving'));
      }
    },
    updateChanges(value) {
      this.hasChanges = value;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_campaign-configuration.scss";
</style>
